import LINKS from '@/constants/links';

export type TLink = {
  name: string;
  isInternal?: boolean;
  link?: string;
  className?: string;
  soon?: boolean;
};

export type TLinkItem = {
  category: string;
  links: TLink[];
};

export const linkItems: TLinkItem[] = [
  {
    category: 'PRO SEU NEGÓCIO',
    links: [
      {
        name: 'Planos',
        link: '/planos/',
        isInternal: true,
      },
      {
        name: 'Maquininha',
        link: '/maquina-de-cartao/',
        isInternal: true,
      },
      {
        name: 'Conta PJ Stone',
        link: '/conta-pj/',
        isInternal: true,
      },
      {
        name: 'Capital de Giro Stone',
        link: '/capital-de-giro/',
        isInternal: true,
      },
    ],
  },
  {
    category: 'FACILITE SUA ROTINA',
    links: [
      {
        name: 'Cartão Stone',
        link: '/cartao-de-credito/',
        isInternal: true,
      },
      {
        name: 'Link de Pagamento',
        link: '/link-de-pagamento/',
        isInternal: true,
      },
      {
        name: 'Pix',
        link: '/pix/',
        isInternal: true,
      },
      {
        name: 'Antecipação',
        link: '/antecipacao/',
        isInternal: true,
      },
      {
        name: 'Controle',
        link: '/gestao/#controle',
        isInternal: true,
      },
    ],
  },
  {
    category: 'PRA VOCÊ QUE É AUTÔNOMO',
    links: [
      {
        name: 'Ton',
        link: 'https://www.ton.com.br/',
        isInternal: false,
      },
    ],
  },
  {
    category: 'MAIS SOLUÇÕES',
    links: [
      {
        name: 'E-commerce',
        link: '/solucoes-de-pagamentos-digitais/',
        isInternal: true,
      },
      {
        name: 'Raio X',
        link: '/gestao/#raio-x',
        isInternal: true,
      },
    ],
  },
  {
    category: 'STONE',
    links: [
      {
        name: 'Por que Stone',
        link: '/modostone/',
        isInternal: true,
      },
      {
        name: 'Nossa História',
        link: '/nossa-historia/',
        isInternal: true,
      },
      {
        name: 'Trabalhe Conosco',
        link: 'https://jornada.stone.com.br/',
        isInternal: false,
      },
      {
        name: 'Parcerias',
        link: 'https://partner.stone.com.br/',
        isInternal: false,
      },
      {
        name: 'Seja um franqueado',
        link: '/franquias/',
        isInternal: false,
      },
      {
        name: 'Investidores',
        link: 'https://investors.stone.co/',
        isInternal: false,
      },
      {
        name: 'Blog Stone',
        link: LINKS.BLOG,
        isInternal: false,
      },
    ],
  },
  {
    category: 'ATENDIMENTO',
    links: [
      {
        name: 'Entre em contato',
        link: '/suporte-stone/',
        isInternal: true,
      },
      {
        name: 'Central de Ajuda',
        link: 'https://ajuda.stone.com.br/',
        isInternal: false,
      },
      {
        name: 'Já sou cliente',
        link: '/suporte-stone/',
        isInternal: true,
      },
      {
        name: 'Seja nosso cliente',
        link: '/adquira/',
        isInternal: true,
      },
      {
        name: 'Ouvidoria',
        link: '/ouvidoria/',
        isInternal: true,
      },
      {
        name: 'Orelhão Stone',
        link: 'https://canalconfidencial.com.br/orelhaostone/',
        isInternal: false,
      },
    ],
  },
  {
    category: 'INSTITUCIONAL',
    links: [
      {
        name: 'Segurança',
        link: '/seguranca/',
        isInternal: true,
      },
      {
        name: 'Contrato',
        link: 'https://docs.stone.com.br/contrato-stone/',
        isInternal: true,
      },
      {
        name: 'Desenvolvedores',
        link: 'https://devcenter.stone.com.br/',
        isInternal: false,
      },
      {
        name: 'Demonstrações Financeiras',
        link: 'https://docs.stone.com.br/demonstracoes-financeiras/',
        isInternal: true,
      },
      {
        name: 'Aviso de Privacidade',
        link: 'https://docs.stone.com.br/aviso-de-privacidade/',
        isInternal: true,
      },
      {
        name: 'Portal de Privacidade',
        link: 'https://privacidade.stone.com.br/',
        isInternal: true,
      },
      {
        name: 'Política de Cookies',
        link: 'https://docs.stone.com.br/politica-de-cookies/',
        isInternal: true,
      },
      {
        name: 'Política de Segurança',
        link: 'https://docs.stone.com.br/politica-de-seguranca-cibernetica/',
        isInternal: true,
      },
      {
        name: 'Política de Educação Financeira',
        link: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1721247280/stone-cms/preprd/Politica_de_Educacao_Financeira_Stone_c4ea9bbb52.pdf',
        isInternal: false,
      },
      {
        name: 'Política de RSAC',
        link: 'https://storage.googleapis.com/public-assets-stg/site/StoneCo-PRSAC.pdf',
        isInternal: false,
      },
      {
        name: 'Relatório GRSAC',
        link: 'https://docs.stone.com.br/relatorios-grsac/',
        isInternal: false,
      },
      {
        name: 'Política Institucional de PLD-FTP | pt-BR',
        link: 'https://docs.stone.com.br/wp-content/uploads/2024/12/politica-institucional_pld-ftp.pdf',
        isInternal: false,
      },
      {
        name: 'Institutional Policy for AML-CFT | en-US',
        link: 'https://res.cloudinary.com/dunz5zfpt/image/upload/stone-cms/prod/Stone_Co_Institutional_Policy_AML_CFTP_2e42d8a3b7.pdf',
        isInternal: false,
      },
    ],
  },
];

export const linkItemsNoMenu: TLinkItem[] = [
  {
    category: 'INSTITUCIONAL',
    links: [
      {
        name: 'Contrato',
        link: 'https://docs.stone.com.br/contrato-stone/',
        isInternal: true,
      },
      {
        name: 'Aviso de Privacidade',
        link: 'https://docs.stone.com.br/aviso-de-privacidade/',
        isInternal: true,
      },
      {
        name: 'Portal de Privacidade',
        link: 'https://privacidade.stone.com.br/',
        isInternal: true,
      },
      {
        name: 'Política de Cookies',
        link: 'https://docs.stone.com.br/politica-de-cookies/',
        isInternal: true,
      },
      {
        name: 'Política de Segurança',
        link: 'https://docs.stone.com.br/politica-de-seguranca-cibernetica/',
        isInternal: true,
      },
      {
        name: 'Política de RSAC',
        link: 'https://storage.googleapis.com/public-assets-stg/site/StoneCo-PRSAC.pdf',
        isInternal: false,
      },
      {
        name: 'Relatório GRSAC',
        link: 'https://docs.stone.com.br/relatorios-grsac/',
        isInternal: false,
      },
      {
        name: 'Política Institucional de PLD-FTP | pt-BR',
        link: 'https://docs.stone.com.br/wp-content/uploads/2024/12/politica-institucional_pld-ftp.pdf',
        isInternal: false,
      },
      {
        name: 'Institutional Policy for AML-CFT | en-US',
        link: 'https://res.cloudinary.com/dunz5zfpt/image/upload/stone-cms/prod/Stone_Co_Institutional_Policy_AML_CFTP_2e42d8a3b7.pdf',
        isInternal: false,
      },
    ],
  },
];

export const linksMasterchef = [
  {
    category: 'INSTITUCIONAL',
    links: [
      {
        name: 'Aviso de Privacidade',
        link: 'https://docs.stone.com.br/aviso-de-privacidade/',
        isInternal: true,
      },
      {
        name: 'Portal de Privacidade',
        link: 'https://privacidade.stone.com.br/',
        isInternal: true,
      },
      {
        name: 'Política de Cookies',
        link: 'https://docs.stone.com.br/politica-de-cookies/',
        isInternal: true,
      },
      {
        name: 'Política de Segurança',
        link: 'https://docs.stone.com.br/politica-de-seguranca-cibernetica/',
        isInternal: true,
      },
    ],
  },
];
